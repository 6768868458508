<template>
    <div>
        <div class="text-left text-xs bg-black text-white opacity-50 p-1">Hero with video background</div>
        <div
           class="bg-gray-200 flex items-center mb-8 px-4 py-24"
        >
            <div
                class="w-full"
                :class="'text-' + getValue('alignment')"
            >
                <p><span class="font-medium text-3xl bg-gray-800 text-white">{{ getValue('headline') }}</span></p>
                <p><span class="bg-gray-800 text-white inline">{{ getValue('subHeadline') }}</span></p>
            </div>
        </div>
    </div>
</template>

<script>
    import Widget from "@/mixins/Widget";

    export default {
        mixins: [ Widget ]
    }
</script>